import Aos from 'aos';
// import Events from './events';
import 'magnific-popup';

jQuery( document ).ready(function($) { 
    // Events();

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');
    
        e.preventDefault();
    
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    }); 

    
    $('.apple-btn').magnificPopup({
      items: {
          src: '#apple-popup',
          type: 'inline'
      }
    });
      

    Aos.init();
});
